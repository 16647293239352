import { useState, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from 'react-toastify'
import logoMundialMix from "../assets/images/logo_mundial_mix.png"
import conglomeradoLogos from "../assets/images/conglomerado_logo.jpg"
import { useNavigate } from "react-router-dom";
import axios from "axios";

const loginSchema = yup.object().shape({
    usuario: yup.string().required("informar o usuário é obrigatório"),
    senha: yup.string().required("informar a senha é obrigatório"),
    empresa: yup.string().required("informar a empresa é obrigatório"),
});
  
const initialValueLogin = {
    usuario: "",
    senha: "",
    empresa: ""
};

const Admin = () => {
    const [companies, setCompanies] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://fin.mundialmixcomercio.com.br:4000/companies');
                setCompanies(response.data.companies);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    }, []);

    const handleFormSubmit = async (values) => {
            const response = await axios.post('https://fin.mundialmixcomercio.com.br:4000/login-admin', {
                username: values.usuario,
                password: values.senha,
                empresa: values.empresa,
            });
    
            if (response.data.access_token) {
                const access_token = response.data.access_token;
                navigate(`/admin/dashboard/${values.empresa}`, {state: { token: access_token, usuario: values.usuario }});
                toast.success(response.data.message);
            } else {
                // Check if the response contains a message
                if (response.data) {
                    toast.error(response.data.message);
                } else {
                    toast.error("Erro desconhecido");
                }
            }
    };

  return (
    <section className='max-w-7xl lg:mx-auto'>
        <div className="flex justify-center">
            <img className="h-[88px]" src={logoMundialMix} alt="Logo da Mundial Mix" />
        </div>
        <div className="flex justify-center w-full">
            <div className="card w-[370px] bg-primary shadow-xl rounded-md">
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValueLogin}
                    validationSchema={loginSchema}
                >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    errors,
                    isValid,
                    dirty
                }) => (
                    <form onSubmit={handleSubmit} className="card-body p-4">
                        <h2 className='card-title flex justify-center text-3xl text-base-100'>Login Dashboard</h2>
                        <div className='form-control w-full'>
                            <label className="label">
                                <span className="label-text pt-6 text-lg sm:text-xl text-base-100">Usuário:</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Usuário"
                                className="p-2 m-1 rounded-md" 
                                value={values.usuario}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="usuario"
                            />
                            {touched.usuario && errors.usuario && (
                                <div className="ml-1 text-base-100">{errors.usuario}</div>
                            )}
                        </div>
                        <div className='form-control w-full'>
                            <label className="label">
                            <span className="label-text pt-1 text-lg sm:text-xl text-base-100">Senha:</span>
                            </label>
                            <input
                                type="password"
                                placeholder="Senha"
                                className="p-2 m-1 rounded-md"
                                value={values.senha}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="senha"
                            />
                            {touched.senha && errors.senha && (
                                <div className="ml-1 text-base-100">{errors.senha}</div>
                            )}
                        </div>
                        <div className='form-control w-full'>
                            <label className="label">
                                <span className="label-text pt-1 text-lg sm:text-xl text-base-100">Empresa:</span>
                            </label>
                            <div className="m-1">
                                {companies.length > 0 ? (
                                <select
                                    className="w-full p-2 rounded-md"
                                    value={values.empresa}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="empresa"
                                >
                                    <option disabled value="" selected>
                                        Empresa
                                    </option>
                                    {companies.map((company) => (
                                        <option>{company.nroempresa}</option>
                                    ))}
                                </select>
                                ) : (
                                    <div className="w-full justify-center flex">
                                        <span className="text-base-100 loading loading-bars loading-lg"></span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card-actions justify-center pt-6">
                            <button
                                type="submit"
                                className='btn btn-secondary w-full text-lg text-base-100'
                                disabled={!dirty || !isValid} // Disable the button if the form is not dirty or not valid
                            >
                                Entrar
                            </button>
                        </div>
                    </form>
                )}
                </Formik>
                <img src={conglomeradoLogos} alt="Logos das Empresas MundialMix" />
            </div>
        </div>
    </section>
    );
};
    
export default Admin;