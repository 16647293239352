import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Digitizer, Home, Admin, Dashboard, NotFound } from "./pages";


function App() {

  return (
    <div style={{ overflowX: 'hidden' }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/digitalizar/:companyid" element={<Digitizer />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/dashboard/:companyid" element={<Dashboard />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
};

export default App