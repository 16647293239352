import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from 'react-toastify'
import logoMundialMix from "../assets/images/logo_mundial_mix.png"
import conglomeradoLogos from "../assets/images/conglomerado_logo.jpg"
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { Camera } from "../components";

const digitizerScheme = yup.object().shape({
    chave: yup
        .string()
        .required("Chave de acesso é obrigatória")
        .matches(/^\d+$/, "Chave de acesso deve conter apenas números")
        .length(44, "Chave de acesso deve conter exatamente 44 caracteres"),
    foto: yup.string().required("Foto do canhoto é obrigatória"),
});
  
const initialValueDigitizer = {
    chave: "",
    foto: ""
};

const Digitizer = () => {
    const [loading, setLoading] = useState(false);
    const [chaveLength, setChaveLength] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const token = location.state?.token;
    const username = (location.state?.usuario || '').toUpperCase();
    
    const { companyid } = useParams();
    
    const [camera, setCamera] = useState(false);
    const [capturedUrl, setCapturedUrl] = useState(null);

    const handleFormSubmit = async (values) => {
        setLoading(true)
        setChaveLength(0)
        try {
          const response = await axios.post(`https://fin.mundialmixcomercio.com.br:4000/image-process/${companyid}`, {
            chave: values.chave,
            foto: values.foto }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) { 
            toast.success("Seu canhoto foi enviado com sucesso")
            alert("Seu canhoto foi enviado com sucesso")
            setLoading(false)
            setCamera(false)
            setCapturedUrl(null)
            values.chave = ''
            values.foto = ''
            document.getElementById("chave").focus();
          }
        } catch (error) {
            if (error.response.status === 403) {
                toast.error("Chave de Acesso Invalida") 
                alert("Chave de Acesso Invalida")
                setLoading(false)
                setCamera(false)
                setCapturedUrl(null)
                values.chave = ''
                document.getElementById("chave").focus();
            } else if (error.response.status === 300) {
                toast.info("Esta chave não pertence a esta empresa") 
                alert("Esta chave não pertence a esta empresa")
                setLoading(false)
                setCamera(false)
                setCapturedUrl(null)
                values.chave = ''
                document.getElementById("chave").focus();
            } else {
                toast.error("Um erro inesperado ocorreu")
                alert("Um erro inesperado ocorreu")
                setLoading(false)
                setCamera(false)
                setCapturedUrl(null)
                values.chave = ''
                document.getElementById("chave").focus();
            }
        }
      };

      useEffect(() => {
        const authLink = async () => {
            try {                
                const response = await axios.post(`https://fin.mundialmixcomercio.com.br:4000/auth/${companyid}`, null, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
        
                if (response.status === 200) {
                    document.getElementById("chave").focus();
                }
            } catch (error) {
                navigate("/");
                toast.error("Autenticação Falhou");
                alert("autenticação Falhou")
            }
        };
    
        authLink();
    }, [companyid, navigate, token]);

    const handleCapture = (url, setFieldValue) => {
        setCapturedUrl(url); // Store the captured URL in the state
        setFieldValue('foto', url);
    };

  return (
    <section className='max-w-7xl lg:mx-auto'>
        <div className="flex justify-center">
            <img className="h-[88px]" src={logoMundialMix} alt="Logo da Mundial Mix" />
        </div>
        <div className="flex justify-center w-full">
            <div className="card w-[370px] bg-primary rounded-md shadow-xl">
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValueDigitizer}
                    validationSchema={digitizerScheme}
                >
                    {({
                        values,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        touched,
                        errors,
                        handleSubmit,
                        resetForm,
                        isValid,
                        dirty
                    }) => (
                        <form onSubmit={handleSubmit} className={`card-body p-4 ${loading? 'h-[400px] flex justify-center text-base-100 loading loading-bars loading-lg translate-x-[165px]' : ''}`}>
                            <h2 className='card-title flex justify-center text-3xl text-base-100'>Digitalizar Canhoto</h2>
                            <h2 className='card-title flex justify-center text-xl text-base-100'>{username} - {companyid}</h2>
                            <div className='form-control w-full'>
                                <label className="label">
                                    <span className="label-text pt-6 text-lg sm:text-xl text-base-100">Chave de acesso:</span>
                                </label>
                                <input
                                    id="chave"
                                    maxLength={44}
                                    autoComplete="off"
                                    type="text"
                                    placeholder="chave de acesso"
                                    className='p-2 m-1 rounded-md'
                                    value={values.chave}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("fotobttn").click();
                                            document.getElementById("fotobttn").focus();
                                        }
                                    }}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                        setFieldValue("chave", numericValue); // Set the numeric value to form field
                                        setChaveLength(numericValue.length);
                                    }}
                                    onBlur={handleBlur}
                                    name="chave"
                                />
                                {touched.chave && errors.chave && (
                                    <div className={`ml-1 text-base-100 ${camera ? "hidden" : ""}`}>{errors.chave}</div>
                                )}
                                <label className="label">
                                    <span className="label-text pt-6 text-lg sm:text-xl text-base-100">Foto do canhoto:</span>
                                </label>
                                <button 
                                    id="fotobttn"
                                    onClick={() => setCamera(true)} 
                                    type="button"
                                    className={`btn btn-secondary ${camera ? "hidden" : ""}`} 
                                    value={values.foto}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="foto"
                                    disabled={chaveLength !== 44}
                                >
                                    Acessar Camera
                                </button>
                                {touched.foto && errors.foto && (
                                    <div className={`ml-1 text-base-100 ${camera ? "hidden" : ""}`}>{errors.foto}</div>
                                )}
                                {camera && (
                                    <div className={`${camera ? "" : "hidden"}`}>
                                        <Camera onCapture={(url) => handleCapture(url, setFieldValue)} capturedUrl={capturedUrl} />
                                    </div>
                                )}
                            </div>
                            <div className="card-actions justify-center pt-6">
                                <button
                                    type="submit"
                                    className={`btn btn-secondary w-full text-lg text-base-100 ${(camera && capturedUrl == null) ? "hidden" : ""}`}
                                    disabled={!dirty || !isValid}
                                >
                                    Enviar
                                </button>
                            </div>
                            <div className="card-actions justify-center pt-1">
                                <button
                                    onClick={() => {
                                        resetForm();
                                        values.chave=''
                                        setCamera(false);
                                        setCapturedUrl(null);
                                        document.getElementById("chave").focus();
                                        setChaveLength(0);
                                    }}
                                    type="button"
                                    className='btn bg-base-200 text-gray-800 w-full text-lg'
                                >
                                    Limpar
                                </button>
                            </div>
                            <div className="card-actions justify-center pt-1">
                                <button
                                    onClick={() => {
                                        navigate("/")
                                    }}
                                    type="button"
                                    className='btn bg-base-200 text-gray-800 w-full text-lg' 
                                >
                                    Sair
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
                <img src={conglomeradoLogos} alt="Logos das Empresas MundialMix" />
            </div>
        </div>
    </section>
  )
}

export default Digitizer

