import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { useParams, useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { GraphicDisplay } from '../components';

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

const loginSchema = yup.object().shape({
    periodode: yup.string().required("informar o período inicial é obrigatório"),
    periodoate: yup.string().required("informar o período final é obrigatório"),
    fornecedor: yup.string().matches(/^\d+$/, "Fornecedor deve conter apenas números")
});
  
const initialValueLogin = {
    periodode: `${date}/${month<10?`0${month}`:`${month}`}/${year}`,
    periodoate: `${date}/${month<10?`0${month}`:`${month}`}/${year}`,
    fornecedor: ""
};

const Dashboard = () => {
    const navigate = useNavigate();
    const [chaves, setChaves] = useState([]);
    const [emailEnviado, setEmailEnviado] = useState('Todos');
    const [loading, setLoading] = useState(false);
    const [calendarDe, setCalendarDe] = useState(false);
    const [calendarAte, setCalendarAte] = useState(false);
    const [selectedDateDe, setSelectedDateDe] = useState(new Date());
    const [selectedDateAte, setSelectedDateAte] = useState(new Date());
    const [SEClist, setSEClist] = useState([]);
    const [SECSearch, setSECSearch] = useState('');
    const [enviarEmails, setEnviarEmails] = useState([]);
    const { companyid } = useParams();
    const location = useLocation();
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const token = location.state?.token;
    const username = (location.state?.usuario || '').toUpperCase();
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);
    const [NFNumber, setNFNumber] = useState('')

    const filteredItems = chaves.filter((chave) => {
        if (emailEnviado === 'E-mail Enviado') {
          return chave[11] === 'S';
        } else if (emailEnviado === 'E-mail Não Enviado') {
          return chave[11] !== 'S' && chave[10] !== 'SEM_URL';
        } else {
          return true;
        }
      });
      
    const totalItems = filteredItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const handleFormSubmit = async (values) => {
        setLoading(true)
        setChaves([]);
        setEnviarEmails('')
        try {
            const response = await axios.post(`https://fin.mundialmixcomercio.com.br:4000/get-canhotos/${companyid}`, {
                periodode: values.periodode,
                periodoate: values.periodoate,
                fornecedor: values.fornecedor,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setChaves(response.data)
            if (response.data.length === 0) {
                toast.info("Nenhum canhoto encontrado")
            }
            setLoading(false)
        } catch (error) {
            if (error.request.status === 401) {
                toast.error('Erro na Autorização do Usuário')
                navigate("/admin");
            }
            toast.error("Erro na Requisição")
            setLoading(false)
        }
    };

    const searchSEC = async () => {
        setLoading(true)
        try {
            const response = await axios.post('https://fin.mundialmixcomercio.com.br:4000/get-SEC', {
                SECSearch: SECSearch,
            }, {
                headers:{
                    Authorization: `Bearer ${token}`,
                },
            });
            setSEClist(response.data)
            if (response.data.length === 0) {
                toast.info("Nenhum SEQ encontrado")
            }
            setLoading(false)
        } catch (error) {
            console.error(error);
            toast.error("Erro na Requisição")
            setLoading(false)
        }
    };

    useEffect(() => {
        const authLink = async () => {
            try {                
                const response = await axios.post(`https://fin.mundialmixcomercio.com.br:4000/auth-admin/${companyid}`, null, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    console.log('Autenticação sucedida');
                }
            } catch (error) {
                console.log('erro:' + error)
                navigate("/admin");
                toast.error("Autenticação Falhou");
            }
        };
    
        authLink();
    }, [companyid, navigate, token]);

    const exportToExcel = () => {
        const filteredChaves = chaves.filter((chave) => {
          if (emailEnviado === 'E-mail Enviado') {
            return chave[11] === 'S';
          } else if (emailEnviado === 'E-mail Não Enviado') {
            return chave[11] !== 'S' && chave[10] !== 'SEM_URL';
          }
          return true;
        });
      
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(filteredChaves);
        
        try {
            ws.A1.v = "Data";
            ws.B1.v = "Empresa";
            ws.C1.v = "SEQ Pessoa";
            ws.D1.v = "Nome Razão";
            ws.E1.v = "Códiogo Geraloper";
            ws.F1.v = "Número NF";
            ws.G1.v = "Série NF";
            ws.H1.v = "Chave";
            ws.I1.v = "Observação";
            ws.J1.v = "E-mails";
            ws.K1.v = "Link Imagem";
            ws.L1.v = "E-mail Enviado";
        } catch (error) {
            console.log(error)
            toast.info("Planilha excel não pode ser vazia")

            return;
        }
      
        XLSX.utils.book_append_sheet(wb, ws, `Dados Canhoto ${companyid}`);
      
        const formatDate = (date) => {
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear().toString();
          return `${day}-${month}-${year}`;
        };
      
        const formattedDateDe = formatDate(selectedDateDe);
        const formattedDateAte = formatDate(selectedDateAte);
      
        XLSX.writeFile(wb, `DadosCanhoto-${companyid}/${formattedDateDe}/${formattedDateAte}.xlsx`);
    };

    const sendNewEmail = async () => {
        setLoading(true)
        setChaves([]);
        try {
            const response = await axios.post('https://fin.mundialmixcomercio.com.br:4000/send-new-email', {
                chaves: enviarEmails,
            }, {
                headers:{
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                document.getElementById("buscar-chave").click();
                setEnviarEmails('')
            }
        } catch (error) {
            toast.error("Erro no Envio do E-mail")
            setLoading(false)
            setEnviarEmails('')
            document.getElementById("buscar-chave").click();
        }
    };

    const handleChange = (chave) => {
        const emailsArray = enviarEmails.split(';');
        const index = emailsArray.indexOf(chave[7]);
        if (index !== -1) {
          emailsArray.splice(index, 1);
        } else {
          emailsArray.push(chave[7]);
        }
        const updatedEnviarEmails = emailsArray.join(';');
        setEnviarEmails(updatedEnviarEmails);
      
        // Update selected checkboxes
        const updatedCheckboxes = selectedCheckboxes.includes(chave[7])
          ? selectedCheckboxes.filter((email) => email !== chave[7])
          : [...selectedCheckboxes, chave[7]];
        setSelectedCheckboxes(updatedCheckboxes);
    };

    const handleEmailEnviado = (e) => {
        setEmailEnviado(e.target.value);
        setCurrentPage(1);
    };

    const selectAll = () => {
        const filteredEmails = chaves
          .filter((chave) => chave[11] !== 'S' && chave[10] !== 'SEM_URL')
          .map((chave) => chave[7]);
      
        const areAllSelected = filteredEmails.every((email) =>
          selectedCheckboxes.includes(email)
        );
      
        const updatedCheckboxes = areAllSelected ? [] : filteredEmails;
        setSelectedCheckboxes(updatedCheckboxes);
      
        // Update enviarEmails
        const updatedEnviarEmails = updatedCheckboxes.join(';');
        setEnviarEmails(updatedEnviarEmails);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }; 

  return (
    <section className='flex justify-center'>
        <div className="p-6 w-full min-h-screen flex justify-center">
            <div className="card bg-base-100 w-full shadow-xl border border-base-200">
                <div className="card-body">
                    <Formik
                    onSubmit={handleFormSubmit} 
                    initialValues={initialValueLogin}
                    validationSchema={loginSchema}
                >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    errors,
                    isValid,
                    setFieldValue
                }) => (
                    <form onSubmit={handleSubmit} className="card-body p-0">
                        <div className="flex-row md:flex md:space-x-6 lg:space-x-10 w-full">
                            <div className='flex w-full lg:space-x-10 md:space-x-6 sm:space-x-2 space-x-1'>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-xl h-8 items-end flex">Período de:</span>
                                    </label>
                                    <input
                                        autoComplete="off"
                                        placeholder="Dia/Mês/Ano"
                                        className={`input input-bordered w-full cursor-pointer ${
                                            calendarDe ? "hidden" : ""
                                        }`}
                                        onClick={() => {
                                            setCalendarDe(true);
                                            setCalendarAte(false);
                                        }}
                                        value={values.periodode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="periodode"
                                    />
                                        {touched.periodode && errors.periodode && (
                                        <div className="text-red-500">{errors.periodode}</div>
                                        )}
                                    <div className={calendarDe ? "" : "hidden"}>
                                    <Calendar
                                        maxDate={new Date()}
                                        className="rounded-lg absolute z-50"
                                        onChange={(date) => {
                                        setSelectedDateDe(date);
                                        const day = date.getDate().toString().padStart(2, "0");
                                        const month = (date.getMonth() + 1).toString().padStart(2, "0");
                                        const year = date.getFullYear();
                                        setFieldValue("periodode", `${day}/${month}/${year}`);
                                        setCalendarDe(false);
                                        }}
                                        value={selectedDateDe}
                                    />
                                    </div>
                                </div>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-xl h-8 items-end flex">Período até:</span>
                                    </label>
                                    <input
                                        autoComplete="off"
                                        placeholder="Dia/Mês/Ano"
                                        className={`input input-bordered w-full cursor-pointer ${
                                            calendarAte ? "hidden" : ""
                                        }`}
                                        onClick={() => {
                                            setCalendarAte(true);
                                            setCalendarDe(false); // Close the other calendar
                                        }}
                                        value={values.periodoate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="periodoate"
                                    />
                                    {touched.periodoate && errors.periodoate && (
                                        <div className="text-red-500">{errors.periodoate}</div>
                                    )}
                                    <div className={calendarAte ?  '' : 'hidden'}>
                                        <Calendar
                                            maxDate={newDate}
                                            className="rounded-lg absolute z-50"
                                            onChange={(date) => {
                                                setSelectedDateAte(date);
                                                setFieldValue(
                                                    "periodoate",
                                                    `${date.getDate()}/${
                                                    date.getMonth() + 1 < 10
                                                        ? `0${date.getMonth() + 1}`
                                                        : `${date.getMonth() + 1}`
                                                    }/${date.getFullYear()}`
                                                );
                                                setCalendarAte(false);
                                            }}
                                            value={selectedDateAte}
                                        />
                                    </div>
                                </div>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-xl h-8 items-end flex">SEQ Fornecedor:</span>
                                    </label>
                                    <div className="form-control">
                                        <div className="input-group">
                                            <input 
                                                id='seq-fornecedor'
                                                type="text" 
                                                autoComplete="off"
                                                placeholder="Número do SEQ" 
                                                className="input input-bordered w-full" 
                                                value={values.fornecedor}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="fornecedor"
                                            />
                                            <p className="btn btn-square" onClick={() => {
                                                document.getElementById('modal_SEC').showModal();
                                                document.getElementById("SECSearch").focus();
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                                            </p>
                                        </div>
                                        {touched.fornecedor && errors.fornecedor && (
                                            <div className="text-red-500">{errors.fornecedor}</div>
                                        )}
                                    </div>
                                    <dialog id='modal_SEC' className="modal cursor-default">
                                        <div className="modal-box">
                                            <form method="dialog">
                                                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                            </form>
                                            <h3 className="font-bold text-lg mt-2">Pesquisar SEQ:</h3>
                                            <form className='flex space-x-4 justify-center pt-4'> 
                                                <input 
                                                    id="SECSearch"
                                                    type="text" 
                                                    placeholder="Ex. Hostin" 
                                                    className="input input-bordered w-full max-w-xs"
                                                    value={SECSearch}
                                                    onChange={(e) => setSECSearch(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                          e.preventDefault();
                                                          searchSEC();
                                                        }
                                                    }}
                                                />
                                                <button onClick={searchSEC} disabled={SECSearch.length <= 2} type='button' className="btn btn-primary text-white">Buscar SEQ</button>
                                            </form>
                                            <div className="overflow-x-auto pt-6">
                                                <table className={`table table-zebra ${loading ? 'hidden' : ''}`}>
                                                    <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Fornecedores</th>
                                                        <th>SEQS</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        {SEClist.map((SEC, index) => (
                                                            <tr
                                                                key={index}
                                                                className='cursor-pointer'
                                                                onClick={() => {
                                                                setFieldValue('fornecedor', SEC[0]);
                                                                document.getElementById('modal_SEC').close();
                                                                }}
                                                            >
                                                                <th>{index + 1}</th>
                                                                <td>{SEC[1]}</td>
                                                                <td>{SEC[0]}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className={`flex w-full justify-center ${loading ? '' : 'hidden'}`}>
                                                    <span className="loading loading-bars loading-lg"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </dialog>
                                </div>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-xl h-8 items-end flex">Número NF:</span>
                                    </label>
                                    <div className="form-control">
                                        <input 
                                            type="number" 
                                            autoComplete="off"
                                            placeholder="Número NF" 
                                            className="input input-bordered w-full" 
                                            value={NFNumber}
                                            onChange={(e) => setNFNumber(e.target.value)}
                                            name="Número NF"
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className='flex w-full lg:space-x-10 md:space-x-6 sm:space-x-2 space-x-1'>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-xl h-8 items-end flex">Enviado:</span>
                                    </label>
                                    <select
                                        className="select select-bordered w-full max-w-xs"
                                        onChange={handleEmailEnviado}
                                        value={emailEnviado}
                                    >
                                        <option selected>Todos</option>
                                        <option>E-mail Enviado</option>
                                        <option>Notas Sem Canhoto</option>
                                        <option>E-mail Não Enviado</option>
                                    </select>
                                </div>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="h-8 items-end flex"></span>
                                    </label>
                                    <button disabled={!isValid} id='buscar-chave' type='submit' className='btn btn-primary text-white w-full text-lg'>
                                        BUSCAR
                                    </button>
                                </div>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="h-8 items-end flex"></span>
                                    </label>
                                    <button
                                        onClick={() => {
                                            window.location.reload();
                                        }}
                                        type="button"
                                        className='btn bg-base-200 text-gray-800 w-full text-lg'
                                    >
                                        Limpar
                                    </button>
                                </div>
                                <div className="form-control w-full hidden lg:block">
                                    <label className="label">
                                        <span className="label-text text-xl font-bold h-8 items-end justify-center w-full flex whitespace-nowrap">{username} - {companyid}</span>
                                    </label>
                                    <button
                                        onClick={() => {
                                            navigate("/admin")
                                        }}
                                        type="button"
                                        className='btn bg-base-200 text-gray-800 w-full text-lg' 
                                    >
                                        Sair
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="form-control w-full lg:hidden">
                            <label className="label">
                                <span className="label-text text-xl font-bold h-8 items-end justify-center w-full flex whitespace-nowrap">{username} - {companyid}</span>
                            </label>
                            <button
                                onClick={() => {
                                    navigate("/admin")
                                }}
                                type="button"
                                className='btn bg-base-200 text-gray-800 w-full text-lg' 
                            >
                                Sair
                            </button>
                        </div>
                    </form>
                )}
                </Formik>
                    <div className="mt-2 flex-row h-full bg-base-100 w-full border border-base-200">
                        <div className="overflow-x-auto h-full">
                            <table className="table table-zebra">
                                <div className={`${chaves.length === 0 && loading === false && companyid == 990 ? '' : 'hidden'}`}>
                                    <GraphicDisplay />
                                </div>
                                <thead>
                                    <tr className={`${chaves.length === 0 ? 'hidden' : ''}`}>
                                        <th className='pl-1 p-0 flex pt-1'>
                                            <button className={`pl-1 pr-2 ${emailEnviado === 'E-mail Não Enviado' ? '' : 'hidden'}`}>
                                                <input
                                                    onClick={() => selectAll()}
                                                    type="checkbox"
                                                    className="checkbox"
                                                />
                                            </button>
                                            <button
                                                onClick={() => sendNewEmail()}
                                                className={`btn btn-sm btn-accent text-white px-1 rounded-r-none ${emailEnviado === 'E-mail Não Enviado' ? '' : 'hidden'}`}
                                            >
                                                E-mail
                                            </button>
                                            <button
                                                onClick={exportToExcel}
                                                className={`btn btn-sm btn-secondary text-white px-1 ${emailEnviado === 'E-mail Não Enviado' ? 'rounded-l-none' : ''}`}
                                            >
                                                Excel
                                            </button>
                                        </th>
                                        <th className={`${emailEnviado === 'Todos' ? '' : 'hidden' }`}>Enviado</th>
                                        <th>Data Emissão</th>
                                        <th>Nome Razão</th>
                                        <th>Empresa</th>
                                        <th>Número NF</th>
                                        <th>Chave</th>
                                        <th>E-mails</th>
                                        <th>Observação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chaves
                                        .filter((chave) => {
                                            if (NFNumber.toString() && !chave[5].toString().includes(NFNumber.toString())) {
                                                return false;
                                            }
                                            if (emailEnviado === 'E-mail Enviado') {
                                                return chave[11] === 'S';
                                            } else if (emailEnviado === 'E-mail Não Enviado') {
                                                return chave[11] !== 'S' && chave[10] !== 'SEM_URL';
                                            } else if (emailEnviado === 'Notas Sem Canhoto') {
                                                return chave[11] !== 'S' && chave[10] === 'SEM_URL'
                                            } else {
                                                return true;
                                            }
                                        })
                                        .slice(startIndex, endIndex)
                                        .map((chave, index) => (
                                        <tr
                                            key={index}
                                        >   
                                            {emailEnviado === 'E-mail Não Enviado' ? 
                                                <td className='mt-8 pb-1 px-2 z-50'>
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={selectedCheckboxes.includes(chave[7])}
                                                        onChange={() => handleChange(chave)}
                                                    />
                                                </td>
                                            :
                                                <th>
                                                    {index + 1}
                                                </th>
                                            }
                                            {chave[11] === 'S' ? 
                                                <td className={`flex items-center ${emailEnviado === 'Todos' ? '' : 'hidden' }`}>
                                                    <input type="checkbox" className="checkbox" checked />
                                                </td>
                                            :
                                                <td className={`flex items-center ${emailEnviado === 'Todos' ? '' : 'hidden' }`}>
                                                    <input type="checkbox" className="checkbox" disabled />
                                                </td>
                                            }
                                            <td onClick={() => document.getElementById(`my_modal_${index}`).showModal()} className='whitespace-nowrap cursor-pointer'>{chave[0]}</td>
                                            <td onClick={() => document.getElementById(`my_modal_${index}`).showModal()} className='whitespace-nowrap cursor-pointer'>{chave[3]}</td>
                                            <td onClick={() => document.getElementById(`my_modal_${index}`).showModal()} className='whitespace-nowrap cursor-pointer'>{chave[1]}</td>
                                            <td onClick={() => document.getElementById(`my_modal_${index}`).showModal()} className='whitespace-nowrap cursor-pointer'>{chave[5]}</td>
                                            <td onClick={() => document.getElementById(`my_modal_${index}`).showModal()} className='whitespace-nowrap cursor-pointer'>{chave[7].slice(0, 25) + '...'}</td>
                                            <td onClick={() => document.getElementById(`my_modal_${index}`).showModal()} className='whitespace-nowrap cursor-pointer'>{chave[9].slice(0, 25) + '...'}</td>
                                            <td onClick={() => document.getElementById(`my_modal_${index}`).showModal()} className='whitespace-nowrap cursor-pointer'>{chave[8].slice(0, 25) + '...'}</td>
                                            <dialog id={`my_modal_${index}`} className="modal cursor-default">
                                                <div className="modal-box w-10/12 max-w-4xl">
                                                    <form method="dialog">
                                                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                                    </form>
                                                    <h3 className="font-bold text-lg flex justify-center cursor-text">{chave[3]}</h3>
                                                    <p className='mx-2 mb-1 mt-4 cursor-text'><span className='font-bold'>Data: </span>{chave[0]}</p>
                                                    <p className='mx-2 mb-1 cursor-text'><span className='font-bold'>Chave: </span>{chave[7]}</p>
                                                    <p className='mx-2 mb-1 cursor-text'><span className='font-bold'>Codgeraloper: </span>{chave[4]}</p>
                                                    <p className='mx-2 mb-1 cursor-text break-words'><span className='font-bold'>E-mails: </span>{chave[9]}</p>
                                                    <p className='mx-2 cursor-text'><span className='font-bold'>Observação: </span>{chave[8]}</p>
                                                    {chave[10] === 'SEM_URL' ? 
                                                        <div className='mt-3 mb-1 mx-2 cursor-text'>
                                                            <p>Sem Imagem Enviada</p>
                                                        </div>
                                                    :
                                                        <div className='mt-3 space-y-3 w-full'>
                                                            <a rel="noreferrer" target='_blank' href={`https://webserver.mundialmixcomercio.com.br/Danfe/${chave[7]}.jpeg`} className="underline text-[#0000EE]">Link Imagem</a>
                                                            <div className='w-full flex justify-center pt-2'>
                                                                <img className='rounded-xl border border-base-300 object-cover' src={`https://webserver.mundialmixcomercio.com.br/Danfe/${chave[7]}.jpeg`} alt="Devolucao Ref. NF: 582916, Série 1, de 03/08/2023, Vlr. orig: 419,00 Ref. NF: 589430, Série 1, de 07/09/2023, Vlr. orig: 1994,95 " />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <form method="dialog" className="modal-backdrop">
                                                    <button className="cursor-default">close</button>
                                                </form>
                                            </dialog>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className={`flex h-[500px] justify-center items-center ${loading? '' : 'hidden'}`}>
                                <span className="loading loading-bars loading-lg"></span>
                            </div>
                        </div>
                    </div>
                    <div className={`flex justify-center py-3 ${chaves.length === 0 || loading === true ? 'hidden' : ''}`}>
                        <div className='pl-4'>
                            <button className='btn btn-sm btn-secondary text-white px-1' onClick={handlePrevPage} disabled={currentPage === 1}>
                                Voltar
                            </button>
                                <span className='px-3'>Página {currentPage} de {totalPages}</span>
                            <button className='btn btn-sm btn-secondary text-white px-1' onClick={handleNextPage} disabled={currentPage === totalPages}>
                                Proxima
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Dashboard