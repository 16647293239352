import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import graphicData from '../data/graphic.json'
import { Chart as Chartjs } from 'chart.js/auto';
import { AiOutlineBarChart, AiOutlineLineChart, AiOutlinePieChart, AiOutlineRadarChart } from 'react-icons/ai';

const options = {
    responsive: true,
    scales: {
        y: {
          beginAtZero: true
        },
        x: {
          display: false
        }
    }, 
}

const GraphicDisplay = () => {
    const [chartType, setChartType] = useState('bar');

    const handleChangeChartType = (newChartType) => {
        setChartType(newChartType);
    };

  const query1 = {
    labels: ['Total', 'Total Sem Canhoto', 'Total Sem Email'],
    datasets: [
      {
        label: 'Notas Emitidas X Notas Sem Canhoto - 30 dias', 
        data: graphicData['query1'][0].map((data) => data),
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const query2 = {
    labels: ['Total', 'Total Sem Canhoto', 'Total Sem Email'],
    datasets: [
      {
        label: 'Notas Emitidas X Notas Sem Canhoto - 90 dias', 
        data: graphicData['query2'][0].map((data) => data),
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const query3 = {
    labels: graphicData['query3'].map((data) => 'Empresa ' + data[0]),
    datasets: [
      {
        label: 'Notas Emitidas', 
        data: graphicData['query3'].map((data) => data[1]),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
      {
        label: 'Notas sem canhoto', 
        data: graphicData['query3'].map((data) => data[3]),
        backgroundColor: [
          'rgba(54, 162, 235, 0.4)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
      {
        label: 'Notas sem E-mail fornecedor - 30 dias', 
        data: graphicData['query3'].map((data) => data[5]),
        backgroundColor: [
          'rgba(255, 206, 86, 0.4)',
        ],
        borderColor: [
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const query4 = {
    labels: graphicData['query4'].map((data) => 'Empresa ' + data[0]),
        datasets: [
        {
          label: 'Notas Emitidas', 
          data: graphicData['query4'].map((data) => data[1]),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
          ],
          borderWidth: 1,
        },
        {
          label: 'Notas sem canhoto', 
          data: graphicData['query4'].map((data) => data[3]),
          backgroundColor: [
            'rgba(54, 162, 235, 0.4)',
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
          ],
          borderWidth: 1,
        },
        {
          label: 'Notas sem E-mail fornecedor - 90 dias', 
          data: graphicData['query4'].map((data) => data[5]),
          backgroundColor: [
            'rgba(255, 206, 86, 0.4)',
          ],
          borderColor: [
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        },
      ],
  };

  return (
    <div className='hidden sm:block'>
        <div className='absolute h-12 w-full border-b border-base-300 bg-base-200'>
            <div className='h-12 flex justify-center pt-2 space-x-2'>
                <button className='btn btn-sm mx-0.5 bg-base-100' onClick={() => handleChangeChartType('bar')}>
                    <AiOutlineBarChart size={24}/>
                </button>
                <button className='btn btn-sm mx-0.5 bg-base-100' onClick={() => handleChangeChartType('line')}>
                    <AiOutlineLineChart size={24}/>
                </button>
                <button className='btn btn-sm mx-0.5 bg-base-100' onClick={() => handleChangeChartType('pie')}>
                    <AiOutlinePieChart size={24}/>
                </button>
                <button className='btn btn-sm mx-0.5 bg-base-100' onClick={() => handleChangeChartType('radar')}>
                    <AiOutlineRadarChart size={24}/>
                </button>
            </div>
        </div>
        <div className='space-y-10 py-16'>
            <div className='flex flex-col space-y-10 lg:space-y-0 lg:flex-row lg:justify-around justify-center'>
              <div className='lg:w-[40%] flex justify-center'>
                  <Chart type={chartType} data={query1} options={options}/>
              </div>
              <div className='lg:w-[40%] flex justify-center'>
                  <Chart type={chartType} data={query2} options={options}/>
              </div>
            </div>
            <div className='flex flex-col space-y-10 lg:space-y-0 lg:flex-row lg:justify-around justify-center'>
              <div className='lg:w-[40%] flex justify-center'>
                  <Chart type={chartType} data={query3} options={options}/>
              </div>
              <div className='lg:w-[40%] flex justify-center'>
                  <Chart type={chartType} data={query4} options={options}/>
              </div>
            </div>
        </div>
    </div>
  );
};

export default GraphicDisplay;