import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
        <div className="px-4 lg:py-12">
          <div className="lg:gap-4 lg:flex">
            <div className="flex flex-col items-center justify-center md:py-24 lg:py-32">
              <h1 className="font-bold text-papasred text-9xl">404</h1>
              <p className="mb-2 text-2xl font-bold text-center md:text-3xl">
                <span className="text-primary">Oops!</span> Página não encontrada
              </p>
              <p className="mb-8 text-center text-gray-500 md:text-lg">
                Favor verificar se a rota inserida existe
              </p>
              <Link
                to="/"
              >
                <button className='btn btn-sm btn-secondary'> 
                    Login Loja
                </button>
              </Link>
              <Link
                to="/admin"
              >
                <button className='btn btn-sm btn-secondary mt-2'> 
                    Login Administração
                </button>
              </Link>
            </div>
          </div>
        </div>
    </div>
  )
}

export default NotFound